import { inject } from '@angular/core';
import { CrmActionButton } from 'common-module/buttons';
import { CrmSafeAny } from 'common-module/core/types';
import { CrmFormProvider } from 'common-module/form';
import {
  CrmModalFormConfigOptions,
  CrmModalFormProvider,
  CrmModalService,
} from 'common-module/modal';
import { CrmTranslateService } from 'common-module/translate';
import { tap } from 'rxjs';

export abstract class ModalFormProvider<
  Result,
  Provider extends CrmFormProvider<CrmSafeAny>,
> extends CrmModalFormProvider<Result, Provider> {
  protected override saveTitle = 'generic.saveChanges';
  protected override cancelTitle = 'generic.cancel';

  protected modalService = inject(CrmModalService);
  protected translate = inject(CrmTranslateService);

  override initModalConfig(
    options: CrmModalFormConfigOptions<CrmModalFormProvider<Result, Provider>>,
  ) {
    return super.initModalConfig(options).pipe(
      tap(() => {
        this.modalRef.updateConfig({
          nzOnCancel: () => this.isCloseable(),
        });
      }),
    );
  }

  protected override saveActionConfig(
    override?: Partial<CrmActionButton>,
  ): CrmActionButton {
    return {
      ...super.saveActionConfig(override),
      isDisabled$: this.isLoading$,
    };
  }

  protected override cancelAction() {
    if (this.isCloseable()) {
      super.cancelAction();
    }
  }

  private isCloseable() {
    if (this.formProvider.form.dirty) {
      this.modalService.confirm({
        nzContent: this.translate.get('generic.discardMessage'),
        nzCancelText: this.translate.get('generic.cancel'),
        nzOnOk: () => this.modalRef.destroy({ success: false }),
        nzOkDanger: true,
      });

      return false;
    }

    return true;
  }
}
